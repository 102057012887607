/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';

import {
  Box,
  useToast,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { BiLockAlt } from 'react-icons/bi';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import formatMoney from '../../Utils/formatMoney';

import KYCModal from '../KYCModal/KYCModal';
import PaymentInfoModal from '../PaymentInfoModal/PaymentInfoModal';

import { useUnlockBalanceMutation } from '../../Redux/features/challenges/challengesApiSlice';
import { useGetPendingBalancesQuery } from '../../Redux/features/auth/authApiSlice';

function truncateTitle(title, maxLength = 20) {
  return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
}

function shouldBlockPendingBalances() {
  const now = new Date();
  const currentDay = now.getDate();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  // Check if it's after the 27th at 23:58
  const isAfter27th =
    currentDay > 27 || (currentDay === 27 && currentHour === 23 && currentMinute >= 58);

  // Check if it's before the 1st of next month
  const isBeforeNextMonth = currentDay !== 1;

  return isAfter27th && isBeforeNextMonth;
}

export default function UserBalance({ userData }) {
  const { balance, region, zexelInfo, isClaiming } = userData;

  // Si tiene o no cuenta ya en Zexel
  const hasZexelConnected = zexelInfo?.email !== undefined;
  const zexelStatus = zexelInfo?.status;

  const { data: pendingBalances } = useGetPendingBalancesQuery();

  const sortedPendingBalance = pendingBalances
    ? [...pendingBalances]
        .map((pendingBalance) => ({
          ...pendingBalance,
          unlockDate: new Date(pendingBalance.unlockDate),
        }))
        .sort((a, b) => a.unlockDate - b.unlockDate)
    : [];

  // Para controlar si bloquear los balances o no (días de procesamiento de pagos)
  const [blockedPendingBalances, setBlockedPendingBalances] = useState(false);

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const [unlockBalance, { isLoading: isLoadingUnlockBalance }] = useUnlockBalanceMutation();

  // Abre modal de datos fiscales
  const {
    isOpen: isOpenTaxModal,
    onOpen: onOpenTaxModal,
    onClose: onCloseTaxModal,
  } = useDisclosure();

  // Abre modal información del % gasto de gestión
  const {
    isOpen: isOpenPaymentInfoModal,
    onOpen: onOpenPaymentInfoModal,
    onClose: onClosePaymentInfoModal,
  } = useDisclosure();

  // Paso del form de datos fiscales
  const [step, setStep] = useState(2);

  // Texto de saldo
  const getBalanceText = (userBalance) => {
    let text = '';
    if (20 - userBalance / 100 <= 0) {
      text = '¡Ya puedes reclamar tu saldo!';
    } else {
      text = `Te faltan aún ${20 - userBalance / 100}${region?.currency === 'EUR' ? '€' : '$'} para reclamar tu saldo`;
    }
    return text;
  };

  // Comprueba si el usuario puede retirar saldo
  const isWithdrawable = balance >= 2000;

  // Acción de retirar saldo o abrir modal de datos fiscales si no está rellenos los datos
  const showRequiredStepTaxModal = () => {
    if (hasZexelConnected && zexelStatus === 'Verified') {
      setStep(1);
      onOpenTaxModal();
    } else {
      setStep(0);
      onOpenTaxModal();
    }
  };

  // Acción del botón una vez ya ha reclamado
  const handleClickClaimedBalance = () => {
    setStep(3);
    onOpenTaxModal();
  };

  // Función para desbloquear balance de un reto
  const handleClickUnlockBalance = async (balanceId) => {
    try {
      await unlockBalance(balanceId).unwrap();
      showToast('¡Se ha añadido el importe a tu saldo disponible!', 'success');
    } catch (error) {
      showToast('Hubo un problema solicitando el saldo, contacta con help@cruwi.com', 'error');
      console.log(error);
    }
  };

  // New state for expanded view
  const [showAllPendingBalances, setShowAllPendingBalances] = useState(false);

  useEffect(() => {
    const checkBlockStatus = () => {
      setBlockedPendingBalances(shouldBlockPendingBalances());
    };

    checkBlockStatus(); // Check immediately
    const interval = setInterval(checkBlockStatus, 300000); // Check every 5 minutes (300,000 milliseconds)

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  // Split pending balances into initial and remaining
  const initialPendingBalances = sortedPendingBalance.slice(0, 3);
  const remainingPendingBalances = sortedPendingBalance.slice(3);

  const renderPendingBalance = (b) => {
    const is30DaysPassed = new Date() >= b.unlockDate;
    const truncatedTitle = truncateTitle(b.challenge.title);
    const numberOfDaysToClaim = Math.max(
      0,
      Math.ceil((b.unlockDate - new Date()) / (1000 * 60 * 60 * 24)),
    );

    return (
      <Flex key={b.challenge._id} flexDir="column" mb="12px">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Box>
              <Box
                mr={2}
                height="50px"
                w="50px"
                border="1px solid black"
                borderRadius="50%"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
                backgroundPosition="center"
                backgroundImage={`url('${b.challenge.brand.logoUrl}')`}
              />
            </Box>
            <Flex flexDir="column">
              <Text fontSize="16px" fontWeight="bold">
                {truncatedTitle}
              </Text>
              <Text fontSize="14px">
                {is30DaysPassed
                  ? '¡Ya puedes añadirlo al saldo!'
                  : `Faltan ${numberOfDaysToClaim < 1 ? 'pocas horas' : `${numberOfDaysToClaim} días`} para añadirlo al saldo`}
              </Text>
            </Flex>
          </Flex>

          <Box>
            {is30DaysPassed ? (
              <Button
                className="button-pulse"
                _hover={{ bg: '#000000' }}
                paddingRight="12px"
                paddingLeft="12px"
                bg="#000000"
                color="white"
                variant="solid"
                size="xs"
                w="60px"
                onClick={() => handleClickUnlockBalance(b.id)}
                isLoading={isLoadingUnlockBalance}
              >
                +{formatMoney(b.reward, region?.locale, region?.currency, 0)}
              </Button>
            ) : (
              <Tooltip label="Debes esperar 30 días desde la publicación">
                <Button disabled variant="solid" size="xs" w="60px">
                  +{formatMoney(b.reward, region?.locale, region?.currency, 0)} 🔒
                </Button>
              </Tooltip>
            )}
          </Box>
        </Flex>
      </Flex>
    );
  };

  return (
    <Box
      maxWidth="700px"
      margin="0 auto"
      p={3}
      bg="#fff"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      mt={5}
      width="100%"
    >
      <Box>
        <Flex flexDir="column">
          <Text fontFamily="DM Sans" fontWeight="bold" fontSize="20px">
            Saldo disponible
          </Text>
          <Text fontFamily="DM Sans" lineHeight={1.7} fontSize="12px" opacity={0.85}>
            {getBalanceText(balance)}
          </Text>
        </Flex>
        <Flex textAlign="center" fontFamily="DM Sans" flexDirection="column">
          <Text fontFamily="DM Sans" fontWeight="extrabold" fontSize="50px">
            {formatMoney(balance, region?.locale, region?.currency)}
          </Text>
        </Flex>
      </Box>
      <Flex alignItems="center">
        <Text
          display="flex"
          alignItems="center"
          onClick={onOpenPaymentInfoModal}
          fontFamily="DM Sans"
          fontSize="12px"
          opacity={0.85}
        >
          Retención del 5% al retirar <InfoOutlineIcon marginLeft="5px" mt="0px" height="12px" />
        </Text>
        <Spacer />
        {isWithdrawable && !blockedPendingBalances && !isClaiming ? (
          <Button
            className="button-pulse"
            _hover={{ bg: '#000000' }}
            paddingRight="12px"
            paddingLeft="12px"
            bg="#000000"
            color="white"
            variant="solid"
            size="xs"
            onClick={() => showRequiredStepTaxModal()}
            fontFamily="DM Sans"
          >
            Retirar {formatMoney(balance, region?.locale, region?.currency)}
          </Button>
        ) : isClaiming ? (
          <Button
            onClick={() => handleClickClaimedBalance()}
            fontFamily="DM Sans"
            variant="solid"
            size="xs"
          >
            Saldo reclamado
          </Button>
        ) : (
          <Tooltip
            label={blockedPendingBalances ? 'Se están procesando los pagos' : 'Debes llegar a 20€'}
          >
            <Button fontFamily="DM Sans" disabled="true" variant="solid" size="xs">
              Retirar &nbsp; <BiLockAlt />
            </Button>
          </Tooltip>
        )}
      </Flex>

      {sortedPendingBalance.length > 0 && (
        <Box fontFamily="DM Sans">
          <Divider mt="12px" borderWidth="1px" borderColor="black" opacity={1} />
          <Box mt="12px" position="relative">
            <Text mb="12px" fontFamily="DM Sans" fontWeight="bold" fontSize="16px">
              Saldos pendientes
            </Text>

            {initialPendingBalances.map(renderPendingBalance)}

            {remainingPendingBalances.length > 0 && (
              <>
                {showAllPendingBalances && remainingPendingBalances.map(renderPendingBalance)}
                <Button
                  onClick={() => setShowAllPendingBalances(!showAllPendingBalances)}
                  variant="solid"
                  size="xs"
                  width="100%"
                  mt={3}
                  borderRadius={8}
                >
                  {showAllPendingBalances ? 'Ver menos' : 'Ver más'}
                </Button>
              </>
            )}

            {blockedPendingBalances && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="rgba(255, 255, 255, 0.95)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDir="column"
                zIndex="1"
              >
                <Text textAlign="center" fontWeight="bold" mb={1}>
                  Se están procesando los pagos 🔒
                </Text>
                <Text textAlign="center" fontWeight="normal" pr={4} pl={4}>
                  Se podrán reclamar los saldos pendientes restantes una vez terminados de procesar
                  los pagos de este mes.
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <PaymentInfoModal isOpen={isOpenPaymentInfoModal} close={onClosePaymentInfoModal} />

      <KYCModal
        step={step}
        hasZexelConnected={hasZexelConnected}
        zexelStatus={zexelStatus}
        userData={userData}
        setStep={setStep}
        isOpen={isOpenTaxModal}
        close={onCloseTaxModal}
        balance={balance}
      />
    </Box>
  );
}

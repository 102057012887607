import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

import LoginForm from '../Components/LoginForm/LoginForm';

function LoginPage() {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);

  const location = useLocation();

  // Obtén la URL original de donde vino el usuario
  const from = location.state?.from
    ? `${location.state.from.pathname}${location.state.from.search || ''}`
    : '/';

  // Si ya tiene token y user, redirigimos a la página de retos (principal)
  if (token && user) {
    return <Navigate to={from} replace />;
  }

  return <LoginForm />;
}

export default LoginPage;

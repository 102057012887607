/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { PhoneNumberUtil } from 'google-libphonenumber';

import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  useBoolean,
  useToast,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';

import YesNoSwitch from '../YesNoSwitch/YesNoSwitch';

import CreatorInfoModal from '../CreatorInfoModal/CreatorInfoModal';

import { setUserData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

// Funciones validación del móvil internacional
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export default function UserData({ userData }) {
  const { phone: userPhone, segmentation } = userData;

  const shouldShowModal =
    Object.keys(userData?.tikTokInfo || {}).length > 0 && userData?.segmentation === undefined;

  // Redux
  const dispatch = useDispatch();
  const [update, { isLoading }] = useUpdateMutation();

  // Toast
  const toast = useToast();

  // Bloqueo de edición
  const [flag, setFlag] = useBoolean(true);

  // Móvil
  const [phone, setPhone] = useState(userPhone);
  const [defaultCountry] = useState('es');

  // Otros campos del usuario
  const [hasChildren, setHasChildren] = useState(
    userData?.segmentation?.hasChildren !== undefined ? userData?.segmentation?.hasChildren : true,
  );
  const [hasPartner, setHasPartner] = useState(
    userData?.segmentation?.hasPartner !== undefined ? userData?.segmentation?.hasPartner : true,
  );
  const [hasPet, setHasPet] = useState(
    userData?.segmentation?.hasPet !== undefined ? userData?.segmentation?.hasPet : true,
  );

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Enviamos al backend los cambios del usuario
  const handleSubmitEditProfile = async () => {
    if (!isPhoneValid(phone)) {
      showToast('El móvil no es correcto', 'error');
      return;
    }

    const updatedUserData = {
      phone,
      segmentation: {
        ...segmentation,
        hasPartner,
        hasPet,
        hasChildren,
      },
    };

    try {
      const userUpdatedData = await update(updatedUserData).unwrap();
      showToast('Datos actualizados correctamente', 'success');
      dispatch(setUserData(userUpdatedData));
    } catch (error) {
      showToast('Hubo un problema actualizando la información.', 'error');
      console.log(error);
    }
  };

  return (
    <Box fontFamily="DM Sans" maxWidth="700px" margin="0 auto">
      <Box mt={6}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Tus datos
        </Heading>
        <Flex justifyContent="space-between">
          <Text maxWidth="250px" fontStyle="italic" opacity="0.8" fontSize="12px">
            Mantén actualizados tus datos para acceder a retos exclusivos
          </Text>
          <Button
            onClick={setFlag.toggle}
            rightIcon={<EditIcon />}
            size="xs"
            colorScheme="black"
            variant="link"
          >
            Editar
          </Button>
        </Flex>
      </Box>

      <Box mt={4} mb={5}>
        {/* Móvil */}
        <FormControl mb={3} isRequired>
          <FormLabel mb="2px">Móvil</FormLabel>
          <PhoneInput
            preferredCountries={['mx', 'ar', 'br', 'co', 'cl', 've']}
            defaultCountry={defaultCountry}
            className="cruwi-phone"
            value={phone}
            onChange={(p) => setPhone(p)}
            disabled={flag}
          />
        </FormControl>

        {/* Tiene hijos */}
        <Box mb={5}>
          <Text lineHeight={1.1} mb={1}>
            ¿Tienes hijo/s?
          </Text>
          <Box>
            <YesNoSwitch
              value={hasChildren}
              onChange={(val) => {
                setHasChildren(val);
              }}
              disabled={flag}
            />
          </Box>
        </Box>

        {/* Tiene pareja */}
        <Box mb={5}>
          <Text lineHeight={1.1} mb={1}>
            ¿Tienes pareja?
          </Text>
          <Box>
            <YesNoSwitch
              value={hasPartner}
              onChange={(val) => {
                setHasPartner(val);
              }}
              disabled={flag}
            />
          </Box>
        </Box>

        {/* Tiene mascota */}
        <Box>
          <Text lineHeight={1.1} mb={1}>
            ¿Tienes mascota?
          </Text>
          <Box>
            <YesNoSwitch
              value={hasPet}
              onChange={(val) => {
                setHasPet(val);
              }}
              disabled={flag}
            />
          </Box>
        </Box>

        <Box mb={2} />
        <Button
          isLoading={isLoading}
          onClick={handleSubmitEditProfile}
          isDisabled={flag}
          mt={2}
          size="md"
          height="38px"
          width="100%"
          fontSize="14px"
          _hover={{ bg: '#FFFFF2' }}
          _focus={{ bg: '#FFFFF2' }}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#FFFFF2"
          color="black"
        >
          Guardar
        </Button>
      </Box>

      <CreatorInfoModal isOpen={shouldShowModal} userData={userData} />
    </Box>
  );
}

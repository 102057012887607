import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

export default function ProtectedRoute({ children, redirectTo = '/login' }) {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  if (!token || !user) {
    // Pass the current location in state so that after login we can navigate back
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  return children;
}

// Helper function to calculate hours left
function getTimeRemainingToSendLink(participationCreatedAt, finalizationDate) {
  const now = new Date();

  // Calculate 72h deadline from participation creation
  const participationDeadline = new Date(participationCreatedAt);
  participationDeadline.setHours(participationDeadline.getHours() + 72);

  // Get the challenge finalization deadline
  const challengeFinalization = new Date(finalizationDate);

  // Use whichever deadline comes first
  const deadline =
    participationDeadline < challengeFinalization ? participationDeadline : challengeFinalization;

  const diff = deadline - now;
  if (diff <= 0) return 0;
  return Math.floor(diff / (1000 * 60 * 60)); // Hours left
}

export default getTimeRemainingToSendLink;

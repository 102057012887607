/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */

import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Divider,
  Box,
  Flex,
  useMediaQuery,
  useToast,
  Button,
  Popover,
  Checkbox,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import { useWithdrawBalanceMutation } from '../../Redux/features/auth/authApiSlice';

import formatMoney from '../../Utils/formatMoney';

import AnimatedCheck from '../AnimatedCheck/AnimatedCheck';

import './KYCModal.css';

const ZEXEL_BASE_URL = 'https://pay.zexel.io';

export default function KYCModal({
  step,
  hasZexelConnected,
  zexelStatus,
  userData,
  setStep,
  isOpen,
  close,
  balance,
}) {
  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Redux
  const [withdrawBalance, { isLoading }] = useWithdrawBalanceMutation();

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // User sign contract terms
  const [sign, setSign] = useState(false);

  // Acción de retirar saldo
  const handleUpdateDataAndWithdrawBalance = async () => {
    if (!sign) {
      showToast('Debes aceptar los términos y condiciones de creador', 'error');
      return;
    }

    try {
      await withdrawBalance().unwrap();
      showToast(
        'Hemos recibido tu solicitud de retirada. Recibirás el pago a través de Zexel el próximo día 28.',
        'success',
      );
      setStep(2);
    } catch (error) {
      showToast('Hubo un problema reclamando tu saldo', 'error');
      console.log(error);
    }
  };

  const encodedEmail = btoa(userData?.email);
  const userId = userData?.id;
  const zexelSignUpUrl = `${ZEXEL_BASE_URL}/auth/signup_linked?emailSource=${encodedEmail}&systemSource=Q3J1d2k=&idSource=${userId}`;

  // Paso 1: Según status en Zexel mostramos un contenido u otro
  let DataStep;
  if (!hasZexelConnected) {
    DataStep = (
      <Flex bg="#fffff2" direction="column" p={5} borderRadius="24px">
        <Text fontFamily="DM Sans" fontSize="22px" fontWeight="bold" mb={2}>
          Antes de continuar...
        </Text>
        <Text fontFamily="DM Sans" mb={2}>
          Para poder hacerte los pagos con seguridad, usamos a nuestro proveedor de pagos Zexel Pay,
          especializado en pagos a creadores de contenido. Tendrás que realizar el alta pulsando en
          el botón para poder recibir tus pagos.
        </Text>

        <Text fontFamily="DM Sans" mb={5}>
          Tranquilo, para próximos pagos no tendrás que hacerlo de nuevo :)
        </Text>

        <Button
          onClick={() => {
            window.open(zexelSignUpUrl);
          }}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          fontFamily="DM Sans"
        >
          Darme de alta en Zexel
        </Button>
      </Flex>
    );
  } else if (hasZexelConnected && zexelStatus === 'Registered') {
    DataStep = (
      <Flex bg="#fffff2" direction="column" p={5} borderRadius="24px">
        <Text fontFamily="DM Sans" fontSize="22px" fontWeight="bold" mb={2}>
          Registro sin verificar
        </Text>

        <Text fontFamily="DM Sans" mb={2}>
          Asegúrate de completar tu registro en Zexel para poder ser verificado y recibir pagos.
          Hasta entonces, no podrás cobrar. Tanto Zexel como nosotros te notificaremos una vez estés
          verificado.
        </Text>

        <Text fontFamily="DM Sans" mb={2}>
          {' '}
          <a
            href={zexelSignUpUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            Accede aquí
          </a>{' '}
          para terminar el registro. <br /> Si ya completaste el registro, solo espera la
          confirmación :)
        </Text>

        <Text fontFamily="DM Sans" mb={2}>
          ¿Tienes dudas? Puedes contactar con Zexel{' '}
          <a
            href="https://wa.me/+34652237124"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            en Whatsapp
          </a>{' '}
          o escribiendo un email a <u>support@zexel.io</u>
        </Text>

        <Button
          onClick={close}
          mt={4}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          fontFamily="DM Sans"
        >
          Entendido
        </Button>
      </Flex>
    );
  } else if (hasZexelConnected && zexelStatus === 'ProfileFilled') {
    DataStep = (
      <Flex bg="#fffff2" direction="column" p={5} borderRadius="24px">
        <Text fontFamily="DM Sans" fontSize="22px" fontWeight="bold" mb={2}>
          Registro completado en Zexel
        </Text>
        <Text fontFamily="DM Sans" mb={2}>
          Estamos esperando que tu perfil sea verificado. Una vez recibas la verificación, ya podrás
          retirar tu saldo como siempre. Este proceso puede tardar varias horas o días.
        </Text>

        <Button
          onClick={close}
          mt={4}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          fontFamily="DM Sans"
        >
          Entendido
        </Button>
      </Flex>
    );
  }

  // Paso 2: Review datos y check (firma)
  const ReadyStep = (
    <Flex direction="column" p={5} minHeight="540px">
      <Box flex="1">
        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Información retirada
        </Text>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
          El próximo día 28 revisaremos tu petición de retirada y, si está todo bien, recibirás el
          dinero al método de cobro que tengas configurado en Zexel.
        </Text>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
          Si hay cualquier problema, nos pondremos en contacto contigo :)
        </Text>

        <Box fontFamily="DM Sans" mb="10px">
          <Popover>
            <PopoverTrigger>
              <Text
                fontFamily="DM Sans"
                fontWeight="semibold"
                fontSize="16px"
                lineHeight={1.3}
                mb={2}
              >
                Desglose del pago <InfoOutlineIcon cursor="pointer" width="10px" />
              </Text>
            </PopoverTrigger>
            <PopoverContent boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
                Información retirada
              </PopoverHeader>
              <PopoverBody>
                <Text fontFamily="DM Sans" fontSize="12px">
                  El importe que recibirás será igual al saldo solicitado menos la comisión de CRUWI
                  que es de un 5%.
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <UnorderedList fontSize="14px">
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={2}>
                Balance bruto reclamado: {formatMoney(balance)}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={3}>
                Comisión de CRUWI (5%): {formatMoney(balance * 0.05)}
              </Text>
            </ListItem>
          </UnorderedList>

          <Divider />

          <Text
            fontWeight="bold"
            fontFamily="DM Sans"
            fontSize="16px"
            lineHeight={1.3}
            mb={2}
            mt={2}
          >
            Importe final a recibir: {formatMoney(balance - balance * 0.05)}
          </Text>
          <Divider />
        </Box>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={9}>
          <u>Importante</u>: Zexel calculará sobre este importe el IRPF y/o IVA que tendrán que
          pagar según tu situación fiscal particular.
        </Text>

        <Box fontFamily="DM Sans" mt={5}>
          <Checkbox
            size="lg"
            name="gdprAccepted"
            isChecked={sign}
            onChange={(e) => setSign(e.target.checked)}
          >
            <Text fontSize="16px">
              Acepto las{' '}
              <a
                style={{ textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cruwi.com/creators-conditions"
              >
                condiciones de creador
              </a>{' '}
            </Text>
          </Checkbox>
        </Box>
      </Box>

      <Box marginTop="50px">
        <Button
          onClick={() => handleUpdateDataAndWithdrawBalance()}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          variant="solid"
          size="md"
          isLoading={isLoading}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          Reclamar {formatMoney(balance - balance * 0.05)}
        </Button>
      </Box>
    </Flex>
  );

  // Paso 3: Reclamación hecha con éxito
  const SuccessStep = (
    <Flex bg="#fffff2" direction="column" p={5} borderRadius="24px">
      <Box>
        <AnimatedCheck />
      </Box>

      <Box maxWidth="250px" margin="10px auto 0 auto">
        <Text textAlign="center" fontFamily="DM Sans" fontSize="14px" lineHeight={1.3} mb={5}>
          Tu solicitud de retirada se ha procesado con éxito
        </Text>
      </Box>

      <Button
        onClick={close}
        mt={2}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#EBD0FF"
        color="black"
        width="100%"
        fontFamily="DM Sans"
      >
        Cerrar
      </Button>
    </Flex>
  );

  // Paso 4: Balance ya reclamado
  const ClaimedBalanceStep = (
    <Flex direction="column" p={5}>
      <Box flex="1">
        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Saldo ya reclamado
        </Text>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
          Ya has solicitado la retirada de tu saldo disponible. Si todo está correcto, el día 28
          recibirás todo el dinero que tengas en tu saldo disponible hasta ese momento a tu cuenta
          de PayPal conectada a CRUWI.
        </Text>

        <Box fontFamily="DM Sans" mb="10px">
          <Popover>
            <PopoverTrigger>
              <Text
                fontFamily="DM Sans"
                fontWeight="semibold"
                fontSize="16px"
                lineHeight={1.3}
                mb={2}
              >
                Desglose del pago <InfoOutlineIcon cursor="pointer" width="10px" />
              </Text>
            </PopoverTrigger>
            <PopoverContent boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
                Información retirada
              </PopoverHeader>
              <PopoverBody>
                <Text fontFamily="DM Sans" fontSize="12px">
                  El importe que recibirás será igual al saldo solicitado menos la comisión de CRUWI
                  que es de un 5% y una retención del 15% en concepto de IRPF.
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <UnorderedList fontSize="14px">
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={2}>
                Balance bruto reclamado: {formatMoney(balance)}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={3}>
                Comisión de CRUWI (5%): {formatMoney(balance * 0.05)}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={3}>
                Retención de IRPF (15%): {formatMoney((balance - balance * 0.05) * 0.15)}
              </Text>
            </ListItem>
          </UnorderedList>

          <Divider />

          <Text
            fontWeight="bold"
            fontFamily="DM Sans"
            fontSize="16px"
            lineHeight={1.3}
            mb={2}
            mt={2}
          >
            Importe final a recibir:{' '}
            {formatMoney(balance - balance * 0.05 - (balance - balance * 0.05) * 0.15)}
          </Text>
          <Divider />
        </Box>
      </Box>

      <Box marginTop="20px">
        <Button
          onClick={close}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          variant="solid"
          size="md"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          Entendido
        </Button>
      </Box>
    </Flex>
  );

  // Renderizado de los pasos
  const renderStep = () => {
    if (step === 0) return DataStep;
    if (step === 1) return ReadyStep;
    if (step === 2) return SuccessStep;
    if (step === 3) return ClaimedBalanceStep;
    return null;
  };

  return (
    <Modal
      size={isLargerThan768 ? 'lg' : 'lg'}
      isOpen={isOpen}
      onClose={close}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'inside'}
    >
      <ModalOverlay />
      <ModalContent
        border="2px solid #EBD0FF"
        borderRadius={isLargerThan768 ? '24px' : '24px'}
        m={isLargerThan768 ? 5 : 5}
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        bg="#feffef"
      >
        <ModalCloseButton bg="#fffff2" borderRadius="50%" m={1} border="1px solid black" />
        <ModalBody padding={0}>{renderStep()}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

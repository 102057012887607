import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

import RegisterFormV3 from '../Components/RegisterFormV3/RegisterFormV3';

export default function RegisterPage() {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  // Obtén la URL original de donde vino el usuario
  const from = location.state?.from
    ? `${location.state.from.pathname}${location.state.from.search || ''}`
    : '/';

  // Si ya se autenticó, redirige a la URL guardada
  if (token && user) {
    return <Navigate to={from} replace />;
  }

  return <RegisterFormV3 />;
}

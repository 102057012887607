/* eslint-disable no-console */

import { useCallback } from 'react';

import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  useToast,
} from '@chakra-ui/react';

import { PiCoin } from 'react-icons/pi';
import { LiaHourglassStartSolid } from 'react-icons/lia';

import BlackCheck from '../../Assets/Images/check-black-fill.svg';
import CruwiIcon from '../../Assets/Images/logo-rayas-cruwi.svg';

import { useAcceptInviteMutation } from '../../Redux/features/challenges/challengesApiSlice';

export default function ChallengeInvitationModal({ isOpen, onClose, invitationId, challengeData }) {
  // Toast
  const toast = useToast();
  const showToast = useCallback(
    (message, type) => {
      toast({
        title: `${message}`,
        status: type,
        isClosable: true,
        duration: 2000,
      });
    },
    [toast],
  );

  const [accept, { isLoading: isLoadingAcceptInvitation }] = useAcceptInviteMutation();

  const handleAccept = async () => {
    try {
      await accept(invitationId).unwrap();
      showToast(`Invitación aceptada correctamente`, 'success');
      onClose();
    } catch (error) {
      showToast(`Hubo un problema aceptando esta invitación`, 'error');
      console.log(error);
    }
  };

  const modalContent = (
    <Box fontFamily="DM Sans">
      <Text fontSize="20px" fontWeight="bold" mb="16px">
        Invitación a un reto
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        Has recibido esta invitación para el reto <strong>{challengeData?.title}</strong>. Pulsando
        en el botón confirmas tu participación en el reto y será añadido a los retos en los que
        participas.
      </Text>

      <Flex flexDir="column">
        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundColor="#FFFFF2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box transform="rotate(270deg)">
              <PiCoin size={14} color="#000" />
            </Box>
          </Box>
          <Flex fontSize="14px">
            <p>
              <b>
                Participar cuesta un crédito (
                <img
                  style={{ display: 'inline', marginTop: '-3px', verticalAlign: 'middle' }}
                  width="20px"
                  src={CruwiIcon}
                  alt=""
                />
                ). <br />
              </b>
              Lo recuperas en cuanto subas el video. Si no lo subes, lo pierdes para siempre.
            </p>
          </Flex>
        </Flex>

        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundColor="#FFFFF2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <LiaHourglassStartSolid size={14} color="#000" />
            </Box>
          </Box>
          <Flex fontSize="14px">
            <p>
              <b>Tienes 72 horas para subir el video </b> desde que participas. Pasado este tiempo
              tu plaza será liberada y perderás el crédito gastado.
            </p>
          </Flex>
        </Flex>

        <Flex mb="12px">
          <Box
            cursor="pointer"
            border="1px solid"
            boxShadow="1px 1px 0px 0px #000"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="12px"
            backgroundPosition="center"
            backgroundColor="#FFFFF2"
            backgroundImage={`url(${BlackCheck})`}
          />
          <Flex fontSize="14px">
            <p>
              <b>La marca no tiene que confirmar tu participación.</b> Cuando participas el
              siguiente paso es subir el video.
            </p>
          </Flex>
        </Flex>
      </Flex>

      <Flex>
        <Button
          onClick={onClose}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="white"
          color="black"
          width="140px"
          mr={2}
        >
          Rechazar
        </Button>
        <Button
          onClick={handleAccept}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isLoading={isLoadingAcceptInvitation}
        >
          Aceptar invitación
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
